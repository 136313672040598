.features {
  background: rgba(57, 31, 91, 1);
  border: 1px solid #5c4678;
  padding-top: 300px;
  position: relative;
  padding-bottom: 128px;

  svg {
    position: absolute;
    top: -86px;
    width: 100%;
    fill: rgba(57, 31, 91, 1);
  }

  .link {
    font-size: 21px;
  }

  @media only screen and (max-width: 992px) {
    padding-top: 40%;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 40%;
  }

  @media only screen and (max-width: 668px) {
    padding-top: 60%;
  }
}
