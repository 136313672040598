footer {
  background: #391f5b;
  border: 1px solid #5c4678;
  padding: 64px 32px;

  h2 {
    font-size: 26px;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    // letter-spacing: unset;
    line-height: 21px;
    margin-bottom: 32px;
  }
}

.footer__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  input {
    border-radius: 42px;
    padding: 16px 32px;
    width: 200px;
    border: 1px solid;
    font-size: 16px;
    font-weight: bold;
    color: #391f5b;
    font-size: 14px;
    outline: unset;
    border: 1px solid #b384c9;
    font-family: "Quicksand";
    box-shadow: 0px 0px 4px rgb(255 255 255 / 30%),
      0px 0px 12px rgb(255 255 255 / 20%), 1px 1px 4px rgb(57 31 91 / 60%),
      4px 4px 16px rgb(57 31 91 / 32%);
  }

  .email__button {
    font-family: "Quicksand";
    margin-left: 16px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.84) -38.04%,
      rgba(231, 218, 237, 0.84) 31.7%,
      rgba(231, 218, 237, 0.84) 51.83%,
      rgba(216, 191, 227, 0.84) 100%
    );
    border: 1px solid rgba(231, 218, 237, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(231, 218, 237, 0.4),
      0px 8px 24px rgba(57, 31, 91, 0.32);
    border-radius: 42px;
    font-size: 16px;
    padding: 16px 28px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    letter-spacing: 0.04em;
    color: #391f5b;

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.84) -38.04%,
        rgba(231, 218, 237, 0.84) 100%
      );
      border: 1px solid rgba(255, 255, 255, 0.4);
      box-sizing: border-box;
      box-shadow: 0px 0px 8px rgba(231, 218, 237, 0.4),
        0px 8px 24px rgba(57, 31, 91, 0.32);
    }
  }

  .success,
  .error {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: white;
  }

  #response {
    display: flex;
    flex-direction: column;

    a {
      color: white;
    }
  }

  .footer__email-section {
    width: 50%;
    text-align: right;

    @media only screen and (max-width: 1024px) {
      width: 60%;
      margin-left: 32px;
    }

    @media only screen and (max-width: 840px) {
      width: 75%;
    }

    @media only screen and (max-width: 800px) {
      margin-left: unset;
      width: 100%;
      text-align: center;
      margin-bottom: 128px;
    }

    @media only screen and (max-width: 490px) {
      text-align: center;
      form {
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
      }

      button {
        margin: 16px 32px 32px 32px;
      }
    }
  }

  .footer__spatium {
    text-align: center;
    
    a {
      color: white;
      text-decoration: none;
      font-weight: 500;
    }
    @media only screen and (max-width: 800px) {
      text-align: center;
    }
  }
}
