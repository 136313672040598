.hero {
  padding-top: 128px;
  background: linear-gradient(
    180deg,
    rgba(231, 218, 237, 1) 0%,
    rgba(216, 191, 227, 1) 100%
  );
  border: 1px solid #5c4678;
  border-top: none;
  text-align: center;

  .button-border {
    margin-bottom: 84px;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .hero__subtitle {
    margin: 32px auto;
    margin-bottom: 64px;
    width: 50%;
    color: #76226f;
    text-shadow: none;

    strong {
      border-bottom: 3px dotted #b384c9;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
      // margin-left: -12px;
      // &:last-of-type {
      // }
    }
  }

  @media only screen and (max-width: 1024px) {
    .hero__subtitle {
      width: 64%;
    }
  }

  @media only screen and (max-width: 700px) {
    h1 {
      font-size: 48px;
    }

    .hero__subtitle {
      font-size: 21px;
      width: fit-content;
      padding: 0 32px;
    }
    // CTA - Add To Browser - Its free
    .button {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 425px) {
    padding-top: 64px;

    h1 {
      font-size: 36px;
    }

    .hero__subtitle {
      font-size: 14px;
      width: fit-content;
      line-height: 21px;
      margin: 16px auto;
      margin-bottom: 32px;
    }

    // CTA - Add To Browser - Its free
    .button {
      font-size: 12px;
      width: fit-content;
      padding: 10px 21px;
      img {
        margin-left: -12px;
        &:last-of-type {
          margin-right: 8px;
        }
      }
    }
  }
}

.hero__browser {
  max-width: 100%;
  margin-bottom: -16%;
  margin-left: 16px;
  margin-right: 16px;
}

.hero__browser-mockup {
  width: 100%;
}

.hero__browser-image-container {
  position: relative;
  height: 600px;

  @media only screen and (max-width: 992px) {
    height: 450px;
  }

  @media only screen and (max-width: 768px) {
    height: 280px;
  }

  @media only screen and (max-width: 668px) {
    height: 200px;
  }

  @media only screen and (max-width: 500px) {
    height: 150px;
  }

  @media only screen and (max-width: 400px) {
    height: 150px;
  }
}

.hero__browser-image {
  position: absolute;
  margin-top: -3px;
  img {
    height: 100%;
    width: 100%;
    border: 4px solid white;
    box-sizing: border-box;
    border-top: unset;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
  }

  @media only screen and (max-width: 500px) {
    img {
      border: 1px solid white;
    }
  }
}

.hero__browser-image-loaded:nth-of-type(1) {
  animation-name: fader;
  animation-delay: 4s;
  animation-duration: 1s;
  z-index: 20;
}

.hero__browser-image-loaded:nth-of-type(2) {
  z-index: 10;
}

@keyframes fader {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hero__browser-image-credit {
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-weight: 500;
  font-size: 8px;
  opacity: 1;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: rgb(231, 218, 237);
  text-shadow: rgb(27 8 53) 0px 2px 4px;
  opacity: 0;

  @media only screen and (max-width: 500px) {
    font-size: 6px;
  }
}
