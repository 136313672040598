.container {
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
}

.split {
  margin: 0 32px;
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.invert {
  @media only screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
}

.split-media {
  width: 40%;
  margin: 0;

  @media only screen and (max-width: 700px) {
    width: 90%;
  }
}

.split-text {
  width: 55%;
  @media only screen and (max-width: 700px) {
    width: 90%;
    margin: 32px 0;
  }
}

.rounded-image {
  width: 100%;
  height: auto;
}

.link {
  color: white;
  font-size: 18px;
  text-decoration: unset;
  margin-top: 16px;
  display: block;
  &:hover {
    text-decoration: underline;
  }
}

// Scrollbar
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  background-color: #d8bfe3;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border: 4px solid #391f5b;
}

::-webkit-scrollbar-track {
  background-color: #391f5b;
}
// Scrollbar - firefox
html {
  scrollbar-color: #d8bfe3 #391f5b;
}

.divider__left {
  margin: 64px 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(179, 132, 201, 0.4) 0%,
    rgba(179, 132, 201, 0) 100%
  );
}

.divider__right {
  margin: 64px 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(179, 132, 201, 0) 0%,
    rgba(179, 132, 201, 0.4) 100%
  );
}
