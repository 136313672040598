.navigation__header {
  background: linear-gradient(0deg, #e7daed 0%, #d8bfe3 100%);
  border: 1px solid #5c4678;
  border-bottom: none;
  border-top: none;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 42px;
}

.navigation__logo {
  svg {
    margin-bottom: -16px;
    margin-left: 16px;
  }

  @media only screen and (max-width: 700px) {
    svg {
      width: 60%;
    }
  }

  @media only screen and (max-width: 500px) {
    text-align: center;
    width: 100%;

    svg {
      margin-left: unset;
    }
  }
}

.navigation__menu {
  display: flex;
  align-items: center;
  margin-right: 32px;

  @media only screen and (max-width: 700px) {
    .button {
      padding: 5px 8px;
      font-size: 14px;
      text-align: center;
      white-space: normal;
      word-wrap: break-word;
    }
  }

  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.navigation__link {
  margin-right: 32px;
  text-decoration: none;
  color: #76226f;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 560px) {
    display: none;
  }
}
