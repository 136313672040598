.button {
  background: linear-gradient(
    180deg,
    rgba(179, 132, 201, 0.84) 0%,
    rgba(57, 31, 91, 0.84) 50%
  );
  box-shadow: 0px 2px 2px rgba(57, 31, 91, 0.24),
    0px 8px 12px rgba(179, 132, 201, 0.4);
  border-radius: 42px;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  text-shadow: 0px 0px 0.42px #ffffff;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4),
    0px 0px 12px rgba(255, 255, 255, 0.2), 1px 1px 4px rgba(57, 31, 91, 0.6),
    4px 4px 16px rgba(57, 31, 91, 0.32);

  &:hover {
    background: linear-gradient(
      180deg,
      rgba(179, 132, 201, 1) 0%,
      rgba(57, 31, 91, 1) 50%
    );
  }
}

.button-border {
  display: inline-flex;
  border-radius: 42px;
  padding: 2px;
  background: linear-gradient(180deg, #d8bfe3 42%, rgba(35, 11, 66, 1) 100%);
}

.button-lg {
  padding: 18px 34px;
  font-weight: bold;
  font-size: 21px;
}
