@import "src/scss/layout/reset";
@import "src/scss/base/helpers";
@import "src/scss/component/button";

@import "src/components/navigation";
@import "src/components/hero";
@import "src/components/features";
@import "src/components/footer";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
}

h1 {
  font-size: 64px;
  font-weight: 600;
  color: #391f5b;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  // color: #391f5b;
  text-shadow: 0px 0px 4px rgb(255 255 255 / 30%),
    0px 0px 12px rgb(255 255 255 / 20%), 1px 1px 4px rgb(57 31 91 / 60%),
    4px 4px 16px rgb(57 31 91 / 32%);
  letter-spacing: 0.04em;
  color: white;
  margin-bottom: 24px;

  @media only screen and (max-width: 700px) {
    font-size: 28px;
  }
}

p {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: rgb(179, 132, 201);
  text-shadow: rgb(27 8 53) 0px 1px 1px;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
}

strong {
  font-weight: bold;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
